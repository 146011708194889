<template>
    <v-row no-gutters>
        <v-col cols="12">
            <v-row justify="center" class="py-5">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card elevation="2" class="my-6">
                        <v-app-bar color="indigo" dark flat dense>
                            <v-app-bar-title>Enterprises <span v-if="list">({{list.length}})</span></v-app-bar-title>
                            <v-spacer/>
                            <v-btn icon @click="openCreateEnterpriseDialog">
                                <font-awesome-icon :icon="['fas', 'plus']" style="font-size: 20px;" fixed-width/>
                            </v-btn>
                        </v-app-bar>
                        <template v-if="isViewReady">
                        <v-list dense nav>
                            <v-list-item v-if="list.length === 0">No enterprises.</v-list-item>
                            <v-list-item v-for="item in list" v-bind:key="item.id" class="my-2" @click="onClickItem(item.id)">
                                <EnterpriseListItem :attr="item" :link="!selectOne"></EnterpriseListItem>
                            </v-list-item>
                        </v-list>
                        <CreateEnterpriseDialog v-model="createEnterpriseDialogVisible" @cancel="createEnterpriseDialogVisible = false" @created="onCreatedEnterprise"/>
                        </template>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';
import EnterpriseListItem from '@/components/list-item/EnterpriseListItem.vue';
import CreateEnterpriseDialog from '@/components/user-dashboard/CreateEnterpriseDialog.vue';
// import { isValidName, compact } from '@/sdk/input';

export default {
    components: {
        EnterpriseListItem,
        CreateEnterpriseDialog,
    },
    data: () => ({
        list: null,
        createEnterpriseDialogVisible: false,
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
            user: (state) => state.user,
            focus: (state) => state.focus,
        }),
        id() {
            return this.policyId;
        },
        label() {
            if (this.selectOne) {
                return 'Select an enterprise';
            }
            switch (this.list.length) {
            case 0:
                return 'No enterprises';
            case 1:
                return '1 enterprise';
            default:
                return `${this.list.length} enterprises`;
            }
        },
        isViewReady() {
            return Array.isArray(this.list);
        },
    },
    watch: {
        displayCreateEnterprise(value) {
            if (value && (this.add || this.create)) {
                this.initAddEnterpriseChoices();
            }
        },
    },

    methods: {
        async loadEnterpriseList() {
            try {
                this.$store.commit('loading', { loadEnterpriseList: true });
                const response = await this.$client.user(this.user.id).enterprise.search();
                if (response?.list) {
                    this.list = response.list;
                }
            } catch (err) {
                console.error('loadEnterpriseList failed', err);
            } finally {
                this.$store.commit('loading', { loadEnterpriseList: false });
            }
        },
        onClickItem(enterpriseId) {
            if (this.selectOne) {
                this.$emit('selected', { enterpriseId });
            }
        },
        openCreateEnterpriseDialog() {
            this.createEnterpriseDialogVisible = true;
        },
        async onCreatedEnterprise() {
            this.createEnterpriseDialogVisible = false;
            this.loadEnterpriseList();
        },
        /*
        async createEnterprise() {
            this.error = false;
            console.log('createEnterprise');
            const request = {
                // name: this.name,
                name: this.newEnterpriseName,
                // agreeToTerms: this.isAgreeToTermsChecked,
                // interactionId: this.interactionId, // will be present if enterprise arrives from an existing interaction, such as clicking on a specific link to get started and we can use this to redirect the enterprise to an appropriate location after enterprise is created
            };
            const response = await this.$client.enterprise.create(request);
            console.log('createEnterprise response: %o', response);
            const { isCreated, id, error } = response;
            if (isCreated) {
                const enterprise = { id, ...request };
                this.list.push(enterprise);
                this.$emit('created-enterprise', enterprise);
                this.$emit('added-enterprise', enterprise);
                this.displayCreateEnterprise = false;
                this.newEnterpriseName = null;
            } else if (error) {
                this.error = error;
            } else {
                this.error = 'Request failed';
            }
        },
        validateCreateEnterprise() {
            if (this.$refs.createEnterpriseFormRef.validate()) {
                this.createEnterprise();
            }
        },
        async addEnterprise() {
            const enterprise = { id: this.addEnterpriseId, label: this.addEnterpriseChoices.find((item) => item.value === this.addEnterpriseId).text };
            this.list.push(enterprise);
            this.$emit('added-enterprise', enterprise);
            this.displayCreateEnterprise = false;
            this.addEnterpriseId = null;
        },
        onSubmitForm() {
            if (this.submitFormTimestamp !== null && this.submitFormTimestamp + 500 > Date.now()) {
                console.log('bounced!');
                return;
            }
            this.submitFormTimestamp = Date.now();
            if (!this.$refs.createEnterpriseFormRef.validate()) {
                return;
            }
            if (this.add) {
                this.addEnterprise();
            } else if (this.create) {
                this.createEnterprise();
            } else {
                console.error('enterprise list misconfigured; either add or create is required to use the dialog');
            }
        },
        */
        async initAddEnterpriseChoices() {
            // create a map of which enterprise ids are already in the list
            const enterpriseMap = {};
            this.list.forEach((item) => {
                enterpriseMap[item.id] = true;
            });
            // when we show the add/create enterprise dialog, load the list of available enterprises to add to the policy (list of all enterprises less the ones already in the policy)
            const result = await this.$client.enterprise.search();
            console.log(`EnterpriseViewList.vue fetch enterprises result: ${JSON.stringify(result)}`);
            if (result && result.list) {
                // filter the results to remove enterprises already in the policy, then convert the available enterprises to a choice list for the v-select [ { text, value }, ... ]
                this.addEnterpriseChoices = result.list.filter((item) => !enterpriseMap[item.id]).map((item) => ({ text: item.name, value: item.id }));
            }
        },
    },

    mounted() {
        this.loadEnterpriseList();
    },
};
</script>
