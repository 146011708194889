<template>
    <v-dialog :value="value" @input="$emit('input', $event.target ? $event.target.value : false)" max-width="600">
        <v-card tile elevation="4" class="pa-0" max-width="600">
            <v-toolbar short flat color="white">
                <v-toolbar-title class="indigo--text">Create New Enterprise</v-toolbar-title>
            </v-toolbar>
            <v-card-text class="px-5">
            <v-form @submit.prevent="onFormSubmit">
                <v-text-field
                    ref="enterpriseNameInput"
                    v-model=newEnterpriseName
                    label="Enterprise Name"
                    :rules="newEnterpriseNameRules"
                    validate-on-blur
                    color="indigo"
                    required
                    hint="The name for the enterprise"
                    type="text"
                    outlined
                >
                </v-text-field>
            </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn elevation="4" class="indigo white--text" @click="onFormSubmit" :disabled="!isFormComplete">
                    <span>Create</span>
                </v-btn>
                <v-btn text color="grey" @click="cancel">
                    <span>Cancel</span>
                </v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { isValidName, compact } from '@/sdk/input';
// import { client } from '@/client';

export default {
    props: {
        value: {
            type: Boolean,
            default: false,
            required: true,
        },
    },

    data() {
        return {
            visible: false, // TODO is this really needed? :value @input above maybe all we need
            list: [],
            // create enterprise
            displayCreateEnterprise: false,
            createEnterpriseForm: null,
            newEnterpriseName: null,
            newEnterpriseNameRules: [
                (v) => !!v || 'Enterprise name is required',
                (v) => !v || isValidName(compact(v)) || 'Enterprise name is required',
            ],
            // add enterprise
            addEnterpriseChoices: [],
            addEnterpriseId: null,
        };
    },

    computed: {
        id() {
            return this.policyId;
        },
        isFormComplete() {
            // TODO: check that enterprise name is not too similar to existing enterprise name
            return typeof this.newEnterpriseName === 'string' && this.newEnterpriseName.trim().length > 0;
        },
    },

    watch: {
        displayCreateEnterprise(value) {
            if (value && (this.add || this.create)) {
                this.newEnterpriseName = '';
                this.initAddEnterpriseChoices();
            }
        },
        value(newValue) {
            console.log(`dialog new value for v-model: ${newValue}`);
            if (newValue) {
                setTimeout(() => { this.$nextTick(() => this.$refs.enterpriseNameInput.focus()); }, 1);
            }
        },
        // TODO is this 'visible' prop really needed? :value @input above maybe all we need
        visible(value) {
            this.$emit('input', value);
        },
    },

    methods: {
        onFormSubmit() {
            if (!this.isFormComplete) {
                return;
            }
            this.createEnterprise();
        },
        cancel() {
            this.$emit('cancel');
        },
        async createEnterprise() {
            try {
                this.error = false;
                this.$store.commit('loading', { createEnterprise: true });
                console.log('createEnterprise');
                const request = {
                    // name: this.name,
                    name: this.newEnterpriseName,
                    // agreeToTerms: this.isAgreeToTermsChecked,
                    // interactionId: this.interactionId, // will be present if enterprise arrives from an existing interaction, such as clicking on a specific link to get started and we can use this to redirect the enterprise to an appropriate location after enterprise is created
                };
                const response = await this.$client.user(this.$store.state.session.userId).self.createEnterprise(request);
                console.log('createEnterprise response: %o', response);
                const { isCreated, id, error } = response;
                if (isCreated) {
                    const enterprise = { id, ...request };
                    this.list.push(enterprise);
                    this.$emit('created-enterprise', enterprise);
                    this.$emit('added-enterprise', enterprise);
                    this.displayCreateEnterprise = false;
                    this.newEnterpriseName = null;
                } else if (error) {
                    this.error = error;
                } else {
                    this.error = 'Request failed';
                }
                this.$emit('created', { /* todo: new enterprise info */ });
            } catch (err) {
                console.error('failed to create enterprise', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { createEnterprise: false });
            }
        },
    },
};
</script>
